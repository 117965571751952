import { render, staticRenderFns } from "./editPassword2.vue?vue&type=template&id=0afcf736&scoped=true&"
import script from "./editPassword2.vue?vue&type=script&lang=js&"
export * from "./editPassword2.vue?vue&type=script&lang=js&"
import style0 from "./editPassword2.vue?vue&type=style&index=0&id=0afcf736&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0afcf736",
  null
  
)

export default component.exports